/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    img: "img",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/presbycusis/'], ['en', 'https://www.hear.com/hearing-loss/presbycusis/'], ['en-US', 'https://www.hear.com/hearing-loss/presbycusis/'], ['en-CA', 'https://ca.hear.com/hearing-loss/presbycusis/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "everything-you-need-to-know-about-presbycusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#everything-you-need-to-know-about-presbycusis",
    "aria-label": "everything you need to know about presbycusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Everything you need to know about presbycusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Presbycusis definition can be complicated but simply put it is a slowly progressing ", React.createElement(_components.a, {
    href: "/hearing-loss/sensorineural",
    className: "c-md-a"
  }, "sensorineural hearing loss"), ". It always affects both ears to the same degree, and usually starts at the age of 50. One characteristic of presbycusis is that ", React.createElement(_components.a, {
    href: "/hearing-loss/high-frequency",
    className: "c-md-a"
  }, "sounds within the high-frequency range are significantly impacted"), " (more so at the start of the illness) more than deeper sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Consequently, speech intelligibility is hampered more severely than the capacity to hear sounds – especially when impaired hearing is subjected to an intensely noisy environment. Doctors use the term “cocktail party effect” to refer to the ability to focus on a single speaker or conversation in a noisy setting. In other words, if a person can hear what someone is saying in a crowded, noisy party, even if they are half way across the room, it is more than likely that he or she is experiencing the cocktail party effect."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-of-presbycusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-of-presbycusis",
    "aria-label": "causes of presbycusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes of presbycusis"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/womenshoot-cafe.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Presbycusis is a medical health condition that develops for a number of reasons, some of which include a wide range of ear disorders. Damaging external influences also play a significant role. Typically, these are patients who have been frequently subjected to blaring noise throughout their lives. Another possible cause is certain medications, commonly referred to as ototoxic medication, that can damage the ear in the long run. Presbycusis is common amongst adults over the age of 65.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There is also a connection between presbycusis and risk factors such as smoking, high blood pressure, and diabetes mellitus. Age-associated processes, like progressive impairment of the hair cells, also play an important role. The hair cell receptors are located in the inner ear’s organ of Corti and are responsible for the actual hearing process. If these are damaged due to continuous noise pollution, for example, external sounds can no longer be properly perceived, resulting in impaired hearing. A genetic predisposition may also be a contributing factor."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosis",
    "aria-label": "diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To correctly diagnose presbycusis or age-associated hearing loss, and initiate the appropriate treatment with a hearing aid, it is imperative to exclude other causes or illnesses. Among other things, this includes the examination of the ear using a specialist microscope (ear microscopy). In patients suffering from presbycusis, the ENT specialist will usually find a normally structured eardrum (i.e. no tear or hole in the eardrum). A pure-tone and speech audiogram are also performed to ultimately confirm the diagnosis. If all other causes can be excluded, the ENT specialist will usually recommend the provision of a hearing aid. Modern hearing aids can treat presbycusis efficiently and restore near-perfect comprehension of speech, tones and sounds."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "speech-audiometry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#speech-audiometry",
    "aria-label": "speech audiometry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Speech audiometry"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During the speech audiometry exam, a certain number of words are presented to the patient via headphones and he/she will have to repeat them. Once the test is complete, the information collected from the exam will help doctor determine if there is a deficit in speech comprehension."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If speech audiometry is performed on a patient with presbycusis, the examination will reveal impaired speech comprehension."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "pure-tone-audiometry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pure-tone-audiometry",
    "aria-label": "pure tone audiometry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pure-tone audiometry"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-loss/audiometry/",
    className: "c-md-a"
  }, "During pure-tone audiometry"), ", patients are subjected to high and low tones at different volumes. The patient is asked to respond to the sounds until they are unable to hear the faintest tone. The results of this exam will consequently yield an auditory threshold curve."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If this examination were to be performed on a patient with presbycusis, a loss in the perception of high tones would become apparent: in such cases, the auditory threshold curve would be inferior, particularly in the high-frequency range."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This type of hearing loss goes beyond the maximum age-associated hearing loss. If the illness has progressed further, deeper tones could also be affected."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/presbycusis-audiogram.jpg",
    alt: "Audiogram",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-of-presbycusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-of-presbycusis",
    "aria-label": "symptoms of presbycusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms of presbycusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many symptoms of the presbycusis that make it easier to identify. But some of the symptoms can be indicative of other health/medical issues."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/resources/hearing-loss/inherited-hearing-loss",
    className: "c-md-a"
  }, "Hereditary factor"), ": Presbycusis can be hereditary just like other medical conditions. The important question to ask is; did your parents or other closely related family members have hearing loss? As you could have inherited it."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Pre-existing medical conditions: other health issues such as high blood pressure or heart disease and can affect the blood supply to the middle ear. Which can be a cause of prebycusis."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Medication: Ototoxic medication such as aspirins can be a cause of hearing loss. Therefore, it is important to use them in moderation and only when necessary."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Noise induced hearing loss (NIHL): People who have been exposed to loud noises over a period of time can have prebycusis. This could be loud noises at work, home or for leisure."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tinnitus: tinnitus is a very common condition. However, many people with prebycusis complain about buzzing or ringing noises in their ears; tinnitus."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you fear that you suffer from any of the symptoms or may have prebycusis then it is important to go to a professional audiologist immediately."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "living-with-presbycusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#living-with-presbycusis",
    "aria-label": "living with presbycusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Living with Presbycusis"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/womenshoot-blonde-tablet.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Once you have been diagnosed with presbycusis it is important to get it treated or following it up with the audiologist. For e.g. if you were having other medical concerns or were suffering with your sight, you would look for a treatment, get glasses etc. Similarily, it is important to get a treatment for this. Leaving it untreated or undealt with could lead to other health conditions such as depression, anxiety and could lead to worsening hearing loss. There are treatments out there to help you and ensure that your hearing doesn’t get worse and you are able to enjoy your daily life normally. So take the next step.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-of-presbycusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-of-presbycusis",
    "aria-label": "treatment of presbycusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment of presbycusis"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/woman-holding-hearing-aid.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Once you have been diagnosed with presbycusis it is important to get it treated or following it up with the audiologist. For e.g. if you were having other medical concerns or were suffering with your sight, you would look for a treatment, get glasses etc. Similarily, it is important to get a treatment for this. Leaving it untreated or undealt with could lead to other health conditions such as depression, anxiety and could lead to worsening hearing loss. There are treatments out there to help you and ensure that your hearing doesn’t get worse and you are able to enjoy your daily life normally. So take the next step.")), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
